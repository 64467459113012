import './App.css';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage.js';
import AboutUsMobile from './pages/AboutUsPage/AboutUsMobile/AboutUsMobile.js';
import Header from './pages/Header/Header.js';
import LandingPage from './pages/LandingPage/LandingPage.js';
import LandingPageMobile from './pages/LandingPage/LandingPageMobile/LandingPageMobile.js';
import {useMediaQuery} from 'react-responsive';
import {Routes, Route, Navigate}  from 'react-router-dom';

function App() {
  const isMobile = useMediaQuery({query: '(max-width:685px)'});
    return (
      <div className="App">
        <Header/>
        <Routes>
          <Route path = "/Home" element={isMobile ? <LandingPageMobile/> : <LandingPage/>}/>
          <Route path="/" element={<Navigate to="/Home"/>}/>
          <Route path="/AboutUs" element={isMobile ? <AboutUsMobile/> : <AboutUsPage/>}/>
        </Routes>
      </div> 
    );
}

export default App;
