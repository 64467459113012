import './Header.css';
import logo from '../../logo.png';
import {ReactComponent as ShoppingCartIcon} from '../../assets/icons/shopping-cart.svg';
import email from '../../assets/icons/email.svg';
import instagram from '../../assets/icons/instagram.svg';
import youtube from '../../assets/icons/youtube.svg';

import {useState} from 'react';
import { useNavigate } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import HeaderMobile from './HeaderMobile/HeaderMobile';


const ButtonPressed = (button) => {
    console.log(button + " has been pressed!");
}

const unHoverColor = { color: '#F8E9D2' }
const hoverColor = { color: '#C6C6C6' }
const unHoverColorIcon = "#F8E9D2";
const hoverColorIcon = "#C6C6C6";

const HeaderButton = ({children, onClick}) => {
    const [color, setColor] = useState(unHoverColor);
    return (
        <button onMouseEnter = {() => setColor(hoverColor)} onMouseLeave = {() => setColor(unHoverColor)} onClick = {onClick} style = {color} className="Text" > 
            {children}
        </button>
    );
}

const ShoppingCart = ({onClick}) => {
    const [color, setColor] = useState(unHoverColorIcon);
    return (
        <ShoppingCartIcon onMouseEnter = {() => setColor(hoverColorIcon)} onMouseLeave = {() => setColor(unHoverColorIcon)} fill = {color} onClick={onClick} height="30" cursor={"pointer"}/>           
    );

}

function Header() {
    const navigate = useNavigate();
    const isMobile = useMediaQuery({query: '(max-width:685px)'});
    if (isMobile) {
        return <HeaderMobile/>
    } else {
        return (
            <div className="Header">
                <div className="Link-Container">
                    <div className="Link-Row">
                        <img src={instagram} className="Link-Icon" alt="Instagram"/>

                        <a className="App-link" href="https://instagram.com/levislaxwax" target="_blank" rel="noopener noreferrer">
                            @LevisLaxWax 
                        </a>
                    </div>
                    <div className="Link-Row">
                        <img src={youtube} className="Link-Icon" alt="Youtube"/>

                        <a className="App-link" href="https://youtube.com/levislaxwax" target="_blank" rel="noopener noreferrer">
                            Levi's Lax Wax 
                        </a>
                    </div>
                    <div className="Link-Row">
                        <img src={email} className="Link-Icon" alt="Email"/>

                        <a className="App-link" href="mailto:levi@levislaxwax.com" target="_blank" rel="noopener noreferrer">
                            levi@levislaxwax.com 
                        </a>
                    </div>
                </div>
                <div className='AppLogoWrapper'>
                    <img src={logo} onClick={() => navigate('/Home')} className="App-logo" alt="Logo"/>
                </div>
                <div className="Button-Container">
                    <HeaderButton className="Text" onClick={() => ButtonPressed("Shop")}>
                        Shop
                    </HeaderButton>
                    <HeaderButton onClick={() => navigate('/AboutUs')} className="Text" >
                        About Us 
                    </HeaderButton>
                    <ShoppingCart onClick={() => ButtonPressed("Shopping Cart")}/>
                </div>
            </div>
        );
    }
}

export default Header;