import './AboutUsMobile.css'


function AboutUsMobile() {
    return (
        <div className='AboutUsMobileSection'>
            <p>Mobile Coming Soon</p>
        </div>
    )
}

export default AboutUsMobile;