import './HeaderMobile.css'

import {ReactComponent as ShoppingCartIcon} from '../../../assets/icons/shopping-cart.svg';
import {ReactComponent as HamburgerIcon} from '../../../assets/icons/hamburger.svg';
import { ReactComponent as Logo } from '../../../assets/MobileLogo.svg';
import { useState } from 'react';


import { useNavigate } from 'react-router-dom';

function DropDownItem(props) {
    return (
        <div className="dropDownItem" onTouchStart={props.onClick}>
            <p>{props.text}</p>
        </div>
    )
}

function HeaderMobile() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    return (
        <div className="HeaderMobile">
            <Logo className="Logo" onClick={() => navigate('/Home')}/>
            <div className="HeaderRow">
                <ShoppingCartIcon style={{alignSelf: 'center'}} height={30}/>
                <HamburgerIcon height={30} width={30} style={{alignSelf: 'center'}} onTouchStart={() => setOpen(!open)} onMouseLeave={() => setOpen(false)}/>
                <div className={`dropdown-menu ${open? 'active' : 'inactive'}`}>
                        <DropDownItem text = {'About Us'} onClick = {() =>  {
                            navigate('/AboutUs');
                            setOpen(false);
                        }}/>
                        <DropDownItem text = {'Shop'} onClick = {() => {
                            console.log("Shop Clicked!");
                            setOpen(false);
                        }}/>
                </div>    
            </div>
        </div>
    );
}

export default HeaderMobile;