import './LandingPage.css';
import surfin from '../../assets/surf.gif';
import Carousel from 'react-multi-carousel';
import waxBoardClose from '../../assets/images/Waxing Board Close.jpg';
import car2 from '../../assets/images/Car 2.jpg';
import waxWater from '../../assets/images/Wax Water.jpg';
import waxingBoard from '../../assets/images/Waxing Board.jpg';
import leviAndAlex from '../../assets/images/Levi and Alex.jpg';
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

function LandingPage() {
    return (
        <div className='Landing-Page'>
            <div>
                <div className="Center-Content">
                    <div className='Text'>
                        • ALL-NATURAL | ECO-FRIENDLY | HAND-MADE SURF WAX •
                    </div>
                    <LandingPageCarousel/>
            <div className="Footer">
                <div className='FooterText' style = {{fontSize:'4vw', color: '#F8E9D2'}}>
                    • Give Earth A Break •
                </div>
            </div>
                </div>
            </div>
            <img src={surfin} className='Surf-Gif' alt="Surfin"></img>
        </div>
    );
}

function LandingPageCarousel () {
    return (
            <Carousel infinite={true} centerMode={true} autoPlay={true} autoPlaySpeed={3000} removeArrowOnDeviceType={["desktop"]} responsive={responsive} swipeable={false} draggable={false} showDots={false}> 
                    <img src={waxBoardClose} className='Carousel-Image' alt="WaxClose"></img>
                    <img src={car2}  className='Carousel-Image'alt="Car2"></img>
                    <img src={waxWater}  className='Carousel-Image'alt="Wax Water"></img>
                    <img src={waxingBoard}  className='Carousel-Image'alt="Waxing Board"></img>
                    <img src={leviAndAlex} className='Carousel-Image' alt="Levi and Alex"></img>
            </Carousel>
    );
}

export default LandingPage;
