import './LandingPageMobile.css'
import waxWater from '../../../assets/images/Wax Water.jpg';
import waxingBoard from '../../../assets/images/Waxing Board.jpg';

function LandingPageMobile() {
    return (
        <div className='LandingPageMobileSection'>
            <img src={waxWater} className='Image' alt='Wax Water'></img>
            <div className='LandingPageMobileContent'>
                <h1 className='LandingPageMobileTitle TextMobile'>ECO FRIENDLY SURF WAX</h1>
                <bl className="TextMobile BulletsMobile">
                    <li>Hand-Made with Environmentally Friendly Materials</li>
                    <li>100% Natural Ingredients</li>
                    <li>Biodegradable and Recyclable Packaging</li>
                    <li>The perfect sustainable alternative to mainstream surf wax brands</li>
                    <li>Based In San Diego</li>
                </bl>
            </div>
            <img src={waxingBoard} className='Image' alt='Wax Board'></img>
            <div className='FooterText' style = {{fontSize:'8vw', color: '#F8E9D2'}}>
                • Give Earth A Break •
            </div>
            <div className='ParagraphContainer' style={{textAlign: 'center'}}>
                <div className="TextMobile BulletsMobile" style={{fontSize: '14px'}}>
                Welcome to Levi’s Lax Wax - Your eco-friendly alternative in a sea of mainstream surf wax brands.
                </div>
                <div className="TextMobile BulletsMobile" style={{fontSize: '14px'}}>
                    As surfing continues to grow and evolve, surfers continue to use waxes from popular brands with petroleum-derived ingredients, such as paraffin wax and petroleum jelly, that contribute to environmental harm.
                </div>
                <div className="TextMobile BulletsMobile" style={{fontSize: '14px'}}>
                    Levi’s Lax Wax is made from a blend of simple, natural ingredients, avoiding these harmful chemicals and enabling us to preserve our passion.
                </div>
            </div>
        </div>
    )
}

export default LandingPageMobile;