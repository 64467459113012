import './AboutUsPage.css'

import { useMediaQuery } from 'react-responsive';
import levi from "../../assets/levi.png";

function AboutUsPage() {
    const isMobile = useMediaQuery({ query: '(max-width:685px)' });
    return isMobile ? <p>About Us</p> : (
        <div className='AboutUsSection'>
            <div className='AboutUsTextSection'>
                <div className='AboutUsHeaderText'>
                    About Us
                </div>
                <div className='AboutUsText'>
                    <p>Welcome to Levi’s Lax Wax - dedicated to providing an eco-friendly solution to the mainstream brands that currently dominate the surf wax market.</p>
                    <p>Ironically, the more surfers continue to use conventional surf waxes, the more they contribute to environmental challenges that threaten the very sport that we cherish. Surfboard wax is essential for all surfers, however the biggest brands on the market today contain petroleum-based ingredients, such as paraffin and petroleum jelly. These waxes eventually degrade off of our surfboards and enter the marine ecosystem, harming marine life and contributing to pollution in our oceans.</p>
                    <p>Levi’s Lax Wax promotes a greener approach to surfing without compromising performance. Our waxes are hand-crafted with sustainable, biodegradable, all-natural ingredients in order to minimize harmful impact on our oceans. Make the switch to Levi’s Lax Wax today and be part of the movement towards a more sustainable surfing future.</p>
                </div>
                <div className='AboutUsFooterText'>GIVE EARTH A BREAK!</div>
            </div>
            <img src={levi} className='SurferGuy' alt='SurferGuy' />
        </div>
    )
}

export default AboutUsPage;